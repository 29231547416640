<template>
  <v-dialog v-model="show" max-width="800px" @closed="v$.$reset()" persistent>
    <v-card>
      <v-form ref="form">
        <v-tabs centered>
          <v-tab>Nova rubrica</v-tab>

          <v-tab-item align="center">
            <div class="pa-5 form-group">
              <v-text-field
                v-model="rubric.dscRubrDadosrubrica"
                maxlength="100"
                :counter="100"
                label="Descrição"
                id="textDescription"
                dense
                outlined
                persistent-placeholder
                :placeholder="obrigatorio"
                :class="{ hasError: $v.rubric.dscRubrDadosrubrica.$error }"
              />

              <v-row>
                <v-col sm="3">
                  <v-text-field
                    v-model="rubric.codRubrIderubrica"
                    maxlength="30"
                    :counter="30"
                    label="Código"
                    id="textCode"
                    dense
                    outlined
                    persistent-placeholder
                    :placeholder="obrigatorio"
                    :class="{ hasError: $v.rubric.codRubrIderubrica.$error }"
                    @input="isValid"
                /></v-col>
                <v-col sm="3">
                  <v-text-field
                    v-model="rubric.ideTabRubrIderubrica"
                    maxlength="8"
                    @input="isValid"
                    id="textIdTab"
                    :counter="8"
                    label="Id. Tabela rubrica"
                    dense
                    outlined
                    persistent-placeholder
                    :placeholder="obrigatorio"
                    :class="{
                      hasError: $v.rubric.ideTabRubrIderubrica.$error,
                    }"
                  />
                  <div v-if="validOrNot === false">Caracter invalido</div>
                  <div class="summary text-red" v-if="!$v.rubric.ideTabRubrIderubrica.maxLength">
                    O identificador de rubrica deve ter no máximo 8 caracteres
                  </div>
                  <div class="summary text-red" v-if="!$v.rubric.ideTabRubrIderubrica.minLength">
                    O identificador de rubrica deve ter no mínimo 2 caracteres
                  </div>
                </v-col>
                <v-col sm="6">
                  <v-autocomplete
                    v-model="rubric.nrInscIdeempregador"
                    label="Empresa"
                    append-icon="mdi-chevron-down"
                    id="idACEmpresa"
                    :items="userEstablishment"
                    item-text="socialName"
                    :item-value="(item) => `${item.details.cnpj}`"
                    :multiple="false"
                    dense
                    outlined
                    persistent-placeholder
                    :placeholder="obrigatorio"
                    :class="{ hasError: $v.rubric.nrInscIdeempregador.$error }"
                  >
                    <template v-slot:item="{ item, attrs, on }" :id="item.cnpj"
                      ><v-list-item v-bind="attrs" v-on="on" #default="{ active }" :id="item.details.cnpj">
                        <v-list-item-content class="text-no-wrap" style="color: primary">
                          {{ item.socialName }}</v-list-item-content
                        >
                      </v-list-item>
                    </template>
                  </v-autocomplete></v-col
                >
              </v-row>
              <v-row>
                <v-col sm="6">
                  <v-autocomplete
                    v-model="rubric.tpRubrDadosrubrica"
                    label="Tipo"
                    id="idACTipe"
                    :items="listaTpRubr"
                    append-icon="mdi-chevron-down"
                    item-text="key"
                    :multiple="false"
                    dense
                    hide-details
                    outlined
                    persistent-placeholder
                    :placeholder="obrigatorio"
                    :class="{ hasError: $v.rubric.tpRubrDadosrubrica.$error }"
                  >
                    <template v-slot:item="{ item, attrs, on }"
                      ><v-list-item v-bind="attrs" v-on="on" #default="{ active }" :id="item.key.substring(0, 10)">
                        <v-list-item-content class="text-wrap" style="color: primary">
                          {{ item.key }}</v-list-item-content
                        >
                      </v-list-item>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col sm="6">
                  <v-autocomplete
                    v-model="rubric.natRubrDadosrubrica"
                    id="idACNature"
                    label="Natureza"
                    :items="listaNaturezas"
                    item-text="key"
                    append-icon="mdi-chevron-down"
                    :multiple="false"
                    dense
                    hide-details
                    outlined
                    persistent-placeholder
                    :placeholder="obrigatorio"
                    :class="{ hasError: $v.rubric.natRubrDadosrubrica.$error }"
                  >
                    <template v-slot:item="{ item, attrs, on }"
                      ><v-list-item v-bind="attrs" v-on="on" #default="{ active }" :id="item.key.substring(0, 4)">
                        <v-list-item-content class="text-wrap" style="color: primary">
                          {{ item.key }}</v-list-item-content
                        >
                      </v-list-item>
                    </template>
                  </v-autocomplete>
                </v-col>

                <v-col sm="3">
                  <v-text-field
                    v-model="rubric.iniValidIderubrica"
                    id="textIniValid"
                    v-mask="'##/####'"
                    label="Início da validade"
                    placeholder="mm/aaaa"
                    :rules="rulesInivalid"
                    dense
                    outlined
                    persistent-placeholder
                    :class="{ hasError: $v.rubric.iniValidIderubrica.$error }"
                  />
                </v-col>
                <v-col sm="3">
                  <v-text-field
                    v-model="rubric.fimValidIderubrica"
                    v-mask="'##/####'"
                    label="Fim da validade"
                    id="textfimValid"
                    placeholder="mm/aaaa"
                    :rules="validFim"
                    dense
                    outlined
                    persistent-placeholder
                    :class="{ hasError: $v.rubric.fimValidIderubrica.$error }"
                  />
                </v-col>

                <v-col sm="6">
                  <v-autocomplete
                    v-model="rubric.codIncCPDadosrubrica"
                    label="Cod. Inc. CP"
                    :items="listaCodIncCP"
                    id="idACCodCP"
                    item-text="key"
                    :multiple="false"
                    dense
                    hide-details
                    append-icon="mdi-chevron-down"
                    outlined
                    persistent-placeholder
                    :placeholder="obrigatorio"
                    :class="{ hasError: $v.rubric.codIncCPDadosrubrica.$error }"
                  >
                    <template v-slot:item="{ item, attrs, on }"
                      ><v-list-item v-bind="attrs" v-on="on" #default="{ active }" :id="item.key.substring(0, 7)">
                        <v-list-item-content class="text-wrap" style="color: #000000">
                          {{ item.key }}</v-list-item-content
                        >
                      </v-list-item>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col sm="6">
                  <v-autocomplete
                    v-model="rubric.codIncIRRFDadosrubrica"
                    label="Cod. Inc. IRRF"
                    :items="listaCodIncIR"
                    id="idACCodIRRF"
                    item-text="key"
                    :multiple="false"
                    append-icon="mdi-chevron-down"
                    dense
                    hide-details
                    outlined
                    persistent-placeholder
                    :placeholder="obrigatorio"
                    :class="{ hasError: $v.rubric.codIncIRRFDadosrubrica.$error }"
                  >
                    <template v-slot:item="{ item, attrs, on }"
                      ><v-list-item v-bind="attrs" v-on="on" #default="{ active }" :id="item.key.substring(0, 7)">
                        <v-list-item-content class="text-wrap"> {{ item.key }}</v-list-item-content>
                      </v-list-item>
                    </template></v-autocomplete
                  >
                </v-col>
                <v-col sm="6">
                  <v-autocomplete
                    v-model="rubric.codIncFGTSDadosrubrica"
                    label="Cod. Inc. FGTS"
                    :items="listaCodIncFgts"
                    id="idACCodFGTS"
                    item-text="key"
                    :multiple="false"
                    dense
                    append-icon="mdi-chevron-down"
                    hide-details
                    outlined
                    persistent-placeholder
                    :placeholder="obrigatorio"
                    :class="{ hasError: $v.rubric.codIncFGTSDadosrubrica.$error }"
                  >
                    <template v-slot:item="{ item, attrs, on }"
                      ><v-list-item v-bind="attrs" v-on="on" #default="{ active }" :id="item.key.substring(0, 7)">
                        <v-list-item-content class="text-wrap" style="color: primary">
                          {{ item.key }}</v-list-item-content
                        >
                      </v-list-item>
                    </template></v-autocomplete
                  >
                </v-col>

                <v-col sm="12">
                  <v-textarea
                    v-model="rubric.observacaoDadosrubrica"
                    maxlength="255"
                    label="Observação"
                    id="textObserv"
                    :counter="255"
                    minlength="1"
                    dense
                    outlined
                    persistent-placeholder
                    :class="{ hasError: $v.rubric.observacaoDadosrubrica.$error }"
                  />
                </v-col>
              </v-row>
              <div class="summary text-red" v-if="invalidForm">Preencher todos os campos obrigatórios</div>
            </div>
          </v-tab-item>
          <v-tab>Processos</v-tab>
          <v-tab-item>
            <div class="pa-5">
              <v-row>
                <v-col cols="12">
                  <v-autocomplete
                    v-model="rubric.listEvtTabRubricaIdeProcessoCP"
                    label="Processos CP(Código do indicativo da suspensão)"
                    append-icon="mdi-chevron-down"
                    :items="listaProcessoCp"
                    :item-text="(item) => `${item.nrProcIdeProcessoCP}(${item.codSuspIdeProcessoCP}) `"
                    :multiple="true"
                    dense
                    return-object
                    hide-details
                    outlined
                    persistent-placeholder
                  />
                </v-col>
                <br />
                <br />
                <v-col cols="12">
                  <v-autocomplete
                    v-model="rubric.listEvtTabRubricaIdeProcessoFGTS"
                    label="Processos FGTS(Código do indicativo da suspensão)"
                    :items="listaProcessoFgts"
                    :item-text="(item) => `${item.nrProcIdeProcessoFGTS}`"
                    :multiple="true"
                    dense
                    append-icon="mdi-chevron-down"
                    hide-details
                    return-object
                    outlined
                    persistent-placeholder
                  />
                </v-col>
                <br />
                <br />
                <v-col cols="12">
                  <v-autocomplete
                    v-model="rubric.listEvtTabRubricaIdeProcessoIRRF"
                    label="Processos IRRF(Código do indicativo da suspensão)"
                    :items="listaProcessoIrrf"
                    :item-text="(item) => `${item.nrProcIdeProcessoIRRF}(${item.codSuspIdeProcessoIRRF})`"
                    :multiple="true"
                    dense
                    return-object
                    append-icon="mdi-chevron-down"
                    hide-details
                    outlined
                    persistent-placeholder
                  />
                </v-col>
              </v-row>
            </div>
          </v-tab-item>
          <v-tab>Aplicabilidade</v-tab>
          <v-tab-item>
            <div class="pa-5">
              <v-textarea
                counter="8000"
                maxlength="8000"
                v-model="rubric.aplicabilidade"
                label="Aplicabilidade"
                dense
                outlined
                persistent-placeholder
              />
            </div>
          </v-tab-item>
        </v-tabs>

        <v-card-actions>
          <v-spacer />
          <v-btn text @click="close"> Cancelar</v-btn>
          <v-btn color="success" :loading="loadingSave" :disabled="enableSaveButton" @click="salvar"> Salvar </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import descCodigosVSelect from '@/assets/json/descCodigosVSelect.json';
import eSocialService from '@/services/eSocialService.js';
import cloneDeep from 'lodash/cloneDeep';
import { mapGetters } from 'vuex';
import AdminServices from '@/services/adminServices.js';
import sortBy from 'lodash/sortBy';
import { required, minLength, maxLength } from 'vuelidate/lib/validators';
import { eventBus } from '../../../../main.js';

const testValidDateFim = (value) => {
  if (value) {
    const parts = value.split('/').map((p) => parseInt(p, 10));
    parts[0] -= 1;

    if (parts[0] < 0 || parts[0] > 11) {
      return false;
    }
    if (parts[1] < 1900 || parts[1] > 2100) {
      return false;
    }

    const d = new Date(parts[1], parts[0], new Date().getDate());

    let atual = new Date();
    atual.setHours(0, 0, 0, 0);

    if (d.getTime() < atual.getTime()) {
      return false;
    }
    return true;
  } else {
    return true;
  }
};

const testValidDateIni = (s) => {
  if (s) {
    const parts = s.split('/').map((p) => parseInt(p, 10));
    parts[0] -= 1;

    if (parts[0] < 0 || parts[0] > 11) {
      return false;
    }
    if (parts[1] < 1900 || parts[1] > 2100) {
      return false;
    }
    const d = new Date(parts[1], parts[0], 1);
    let atual = new Date();

    if (d.getTime() > atual.getTime()) {
      return false;
    }

    if (d.getMonth() === parts[0] && d.getFullYear() === parts[1]) {
      return true;
    }
  } else {
    return true;
  }
};
export default {
  name: 'ModalRubricNova',

  data() {
    return {
      validOrNot: true,
      notValidDateFim: false,
      notValidDate: false,
      invalidForm: false,

      message: '',
      userEstablishment: [],
      show: false,
      loadingSave: false,
      obrigatorio: 'Obrigatório',
      rubric: {
        dscRubrDadosrubrica: '',
        codRubrIderubrica: '',
        ideTabRubrIderubrica: '',
        nrInscIdeempregador: '',
        tpRubrDadosrubrica: '',
        natRubrDadosrubrica: '',
        iniValidIderubrica: '',
        fimValidIderubrica: '',
        codIncCPDadosrubrica: '',
        codIncIRRFDadosrubrica: '',
        codIncFGTSDadosrubrica: '',
        observacaoDadosrubrica: '',
      },
      listaProcessoCp: [],
      listaProcessoIrrf: [],
      listaProcessoFgts: [],
      listaProcessoSind: [],
      listaNaturezas: descCodigosVSelect.natRubr,
      listaTpRubr: descCodigosVSelect.tpRubr,
      listaCodIncCP: descCodigosVSelect.codIncCP,
      listaCodIncIR: descCodigosVSelect.codIncIR,
      listaCodIncFgts: descCodigosVSelect.codIncFGTS,
      listaProcessoCpHeader: [
        { align: 'center', text: 'Código Suspensão Id. Processo CP', value: 'codSuspIdeProcessoCP' },
        { align: 'center', text: 'Extensão decisão Processo CP', value: 'extDecisaoIdeProcessoCP' },
        { align: 'center', text: 'Numero identificador processo CP', value: 'nrProcIdeProcessoCP' },
        { align: 'center', text: 'Tipo Processo CP', value: 'tpProcIdeProcessoCP' },
      ],

      listaProcessoIrffHeader: [
        { align: 'center', text: 'Numero identificador processo IRRF', value: 'nrProcIdeProcessoIRRF' },
        { align: 'center', text: 'Código Suspensão Id. Processo IRRF', value: 'codSuspIdeProcessoIRRF' },
      ],

      listaProcessoFgtsHeader: [
        { align: 'center', text: 'Numero identificador processo FGTS', value: 'nrProcIdeProcessoFGTS' },
      ],
      rulesInivalid: [(v) => this.testValidDateIni(v) || '', (v) => isNaN(v)],
      validFim: [(v) => this.isValidFimDate(v) || ''],
      toast: {
        message: '',
        type: '',
        show: false,
        topToast: 0,
      },
    };
  },

  computed: {
    ...mapGetters({
      usuario: 'user/getUserDetails',
    }),
    enableSaveButton() {
      let ar = Object.entries(this.rubric);
      let count = 0;

      ar.forEach((i) => {
        if (i != 0 && i[0] !== 'observacaoDadosrubrica' && i[1] != '') {
          count++;
        } else if (i[0] == 'fimValidIderubrica' && i[1] == '') {
          count++;
        }
      });
      if (count >= 11) {
        return false;
      } else {
        return true;
      }
    },
  },
  validations: {
    rubric: {
      dscRubrDadosrubrica: { required, minLength: minLength(3), maxLength: maxLength(100) },
      codRubrIderubrica: { required, minLength: minLength(3), maxLength: maxLength(30) },
      ideTabRubrIderubrica: { required, minLength: minLength(2), maxLength: maxLength(8) },
      nrInscIdeempregador: { required },
      tpRubrDadosrubrica: { required },
      natRubrDadosrubrica: { required },
      iniValidIderubrica: { required, testValidDateIni },
      fimValidIderubrica: { testValidDateFim, $autoDirty: true },
      codIncCPDadosrubrica: { required },
      codIncIRRFDadosrubrica: { required },
      codIncFGTSDadosrubrica: { required },
      observacaoDadosrubrica: { maxLength: maxLength(255) },
    },
  },

  created() {
    this.init();
  },

  methods: {
    init() {
      this.$on('open', this.open);
      this.$on('close', this.close);
    },

    open() {
      this.show = true;
      this.getCompanies();
      this.getProcess();
    },

    close() {
      this.show = false;
      this.rubric = {
        dscRubrDadosrubrica: '',
        codRubrIderubrica: '',
        ideTabRubrIderubrica: '',
        nrInscIdeempregador: '',
        tpRubrDadosrubrica: '',
        natRubrDadosrubrica: '',

        fimValidIderubrica: '',
        codIncCPDadosrubrica: '',
        codIncIRRFDadosrubrica: '',
        codIncFGTSDadosrubrica: '',
        observacaoDadosrubrica: '',
      };
      //this.$v.rubric.errors.$reset();
      this.$v.$reset();
    },

    isValid(item) {
      let array = Array.from(item);
      var format =
        /[!"#$%&'()*+,-./0123456789:;<=>?@ABCDEFGHIJKLMNOPQRSTUVWXYZ[\\\]^_`abcdefghijklmnopqrstuvwxyz{|}~¡¢£¥§©ª«¬®¯°±²³µ¶·¹º»ÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖ×ØÙÚÛÜÝÞßàáâãäåæçèéêëìíîïðñòóôõö÷øùúûüýþÿ]/;
      for (let value of array) {
        if (format.test(value) || value === '') {
          this.validOrNot = true;
          return this.validOrNot;
        } else if (!format.test(value) || value === ' ') {
          this.validOrNot = false;
          return this.validOrNot;
        }
      }
    },
    isValidFimDate(s) {
      if (s) {
        const parts = s.split('/').map((p) => parseInt(p, 10));
        parts[0] -= 1;

        if (parts[0] < 0 || parts[0] > 11) {
          return 'Mês Incorreto';
        }
        if (parts[1] < 1900 || parts[1] > 2100) {
          return 'Ano incorreto';
        }

        const d = new Date(parts[1], parts[0], new Date().getDate());

        let atual = new Date();
        atual.setHours(0, 0, 0, 0);

        if (d.getTime() < atual.getTime()) {
          return 'Data deve ser maior ou igual à data atual';
        }
        return true;
      } else {
        return true;
      }
    },
    testValidDateIni(s) {
      if (s) {
        const parts = s.split('/').map((p) => parseInt(p, 10));
        parts[0] -= 1;

        if (parts[0] < 0 || parts[0] > 11) {
          return 'Mês Incorreto';
        }
        if (parts[1] < 1900 || parts[1] > 2100) {
          return 'Ano incorreto';
        }
        const d = new Date(parts[1], parts[0], 1);
        let atual = new Date();

        if (d.getTime() > atual.getTime()) {
          return 'Data deve ser menor ou igual à data atual';
        }

        return true;
      } else {
        return true;
      }
    },

    async getProcess() {
      let processCP = await this.getProcessCP();
      this.listaProcessoCp = processCP;

      let processIRRF = await this.getProcessIRRF();
      this.listaProcessoIrrf = processIRRF;

      let processFGTS = await this.getProcessFGTS();
      this.listaProcessoFgts = processFGTS;
    },

    async getProcessCP() {
      let { data } = await eSocialService.evttabrubrica.getProcessCP();
      let cp = data;
      return cp;
    },
    async getProcessIRRF() {
      let { data } = await eSocialService.evttabrubrica.getProcessIRRF();
      let irrf = data;
      return irrf;
    },
    async getProcessFGTS() {
      let { data } = await eSocialService.evttabrubrica.getProcessFGTS();
      let fgts = data;
      return fgts;
    },

    async getCompanies() {
      let { data } = await AdminServices.userEstablishment.get(this.usuario.id);

      let userEstablishments = data.map((item) => item.establishment);

      userEstablishments = sortBy(userEstablishments, ['socialName', 'stateCode', 'city']);
      let establishments = userEstablishments.filter(
        (v, i, a) => a.findIndex((t) => t.details.socialName === v.details.socialName) === i,
      );

      this.userEstablishment = establishments;
    },
    async salvar() {
      this.$v.rubric.$touch();

      if (this.$v.rubric.$error || this.$v.rubric.$pending) {
        this.$v.$reset();
        this.invalidForm = true;
        return;
      }
      this.loadingSave = true;
      try {
        let itemToSave = cloneDeep(this.rubric);

        if (itemToSave.iniValidIderubrica) {
          const iniValidIderubricaSplit = itemToSave.iniValidIderubrica.split('/');
          itemToSave.iniValidIderubrica = `${iniValidIderubricaSplit[1]}-${iniValidIderubricaSplit[0]}`;
        }
        if (itemToSave.fimValidIderubrica) {
          const fimValidIderubricaSplit = itemToSave.fimValidIderubrica.split('/');
          itemToSave.fimValidIderubrica = `${fimValidIderubricaSplit[1]}-${fimValidIderubricaSplit[0]}`;
        }

        if (itemToSave.tpRubrDadosrubrica) {
          itemToSave.tpRubrDadosrubrica = itemToSave.tpRubrDadosrubrica.substring(0, 1);
        }

        if (itemToSave.natRubrDadosrubrica) {
          itemToSave.natRubrDadosrubrica = itemToSave.natRubrDadosrubrica.substring(0, 4);
        }
        if (itemToSave.codIncCPDadosrubrica) {
          itemToSave.codIncCPDadosrubrica = itemToSave.codIncCPDadosrubrica.substring(0, 2);
        }
        if (itemToSave.codIncIRRFDadosrubrica) {
          itemToSave.codIncIRRFDadosrubrica = itemToSave.codIncIRRFDadosrubrica.substring(0, 2);
        }
        if (itemToSave.codIncFGTSDadosrubrica) {
          itemToSave.codIncFGTSDadosrubrica = itemToSave.codIncFGTSDadosrubrica.substring(0, 2);
        }
        if (itemToSave.nrInscIdeempregador) {
          itemToSave.nrInscIdeempregador = itemToSave.nrInscIdeempregador.substring(0, 8);
        }
        let cleared = await this.clearProcess(itemToSave);

        itemToSave = await this.setDefaultItems(cleared);
        itemToSave.status = 'PENDENTE_APROVACAO';

        let { data } = await eSocialService.evttabrubrica.save(itemToSave);
        //this.$emit('added', itemToSave.status);

        let itemHistorico = { idEvtTabRubrica: data.id, rubricaHistorico: itemToSave };

        await eSocialService.rubricahistorico.save(itemHistorico);

        this.showToast(`Rubrica adicionada com sucesso!`, 'success', 78);
        this.close();
      } catch (e) {
        console.log(e);
      }
      this.loadingSave = this.invalidForm = false;
    },
    filter(evt) {
      evt = evt ? evt : window.event;
      let expect = evt.target.value.toString() + evt.key.toString();

      if (!/^[-+]?[0-9]*\.?[0-9]*$/.test(expect)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    clearProcess(itemToSave) {
      if (itemToSave.listEvtTabRubricaIdeProcessoCP) {
        let dirtyProcessCP = itemToSave.listEvtTabRubricaIdeProcessoCP;
        let cleanProcessCP = dirtyProcessCP.map((item) => {
          delete item.id;
          return item;
        });
        itemToSave.listEvtTabRubricaIdeProcessoCP = cleanProcessCP;
      }
      if (itemToSave.listEvtTabRubricaIdeProcessoIRRF) {
        let dirtyProcessIRRF = itemToSave.listEvtTabRubricaIdeProcessoIRRF;
        let cleanProcessIRRF = dirtyProcessIRRF.map((item) => {
          delete item.id;
          return item;
        });
        itemToSave.listEvtTabRubricaIdeProcessoIRRF = cleanProcessIRRF;
      }
      if (itemToSave.listEvtTabRubricaIdeProcessoFGTS) {
        let dirtyProcessFGTS = itemToSave.listEvtTabRubricaIdeProcessoFGTS;
        let cleanProcessFGTS = dirtyProcessFGTS.map((item) => {
          delete item.id;
          return item;
        });
        itemToSave.listEvtTabRubricaIdeProcessoFGTS = cleanProcessFGTS;
      }
      return itemToSave;
    },
    setDefaultItems(itemToSave) {
      itemToSave.operacao = 'Inclusao';
      itemToSave.tpAmbIdeevento = '1';

      itemToSave.procEmiIdeevento = '1';

      itemToSave.tpInscIdeempregador = '1';
      return itemToSave;
    },
    showToast(message, type, topToast) {
      this.toast.show = true;
      this.toast.type = type;
      this.toast.message = message;
      this.toast.topToast = topToast;
      eventBus.$emit('openToast', this.toast);
    },
  },
};
</script>
<style>
#idACEmpresa {
  color: var(--v-primary-base) !important;
}
#idACTipe {
  color: var(--v-primary-base) !important;
}
#idACNature {
  color: var(--v-primary-base) !important;
}
#idACCodCP {
  color: var(--v-primary-base) !important;
}
#idACCodIRRF {
  color: var(--v-primary-base) !important;
}
#idACCodFGTS {
  color: var(--v-primary-base) !important;
}
</style>
