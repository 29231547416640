var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "800px", persistent: "" },
      on: {
        closed: function ($event) {
          return _vm.v$.$reset()
        },
      },
      model: {
        value: _vm.show,
        callback: function ($$v) {
          _vm.show = $$v
        },
        expression: "show",
      },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-form",
            { ref: "form" },
            [
              _c(
                "v-tabs",
                { attrs: { centered: "" } },
                [
                  _c("v-tab", [_vm._v("Nova rubrica")]),
                  _c("v-tab-item", { attrs: { align: "center" } }, [
                    _c(
                      "div",
                      { staticClass: "pa-5 form-group" },
                      [
                        _c("v-text-field", {
                          class: {
                            hasError: _vm.$v.rubric.dscRubrDadosrubrica.$error,
                          },
                          attrs: {
                            maxlength: "100",
                            counter: 100,
                            label: "Descrição",
                            id: "textDescription",
                            dense: "",
                            outlined: "",
                            "persistent-placeholder": "",
                            placeholder: _vm.obrigatorio,
                          },
                          model: {
                            value: _vm.rubric.dscRubrDadosrubrica,
                            callback: function ($$v) {
                              _vm.$set(_vm.rubric, "dscRubrDadosrubrica", $$v)
                            },
                            expression: "rubric.dscRubrDadosrubrica",
                          },
                        }),
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { attrs: { sm: "3" } },
                              [
                                _c("v-text-field", {
                                  class: {
                                    hasError:
                                      _vm.$v.rubric.codRubrIderubrica.$error,
                                  },
                                  attrs: {
                                    maxlength: "30",
                                    counter: 30,
                                    label: "Código",
                                    id: "textCode",
                                    dense: "",
                                    outlined: "",
                                    "persistent-placeholder": "",
                                    placeholder: _vm.obrigatorio,
                                  },
                                  on: { input: _vm.isValid },
                                  model: {
                                    value: _vm.rubric.codRubrIderubrica,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.rubric,
                                        "codRubrIderubrica",
                                        $$v
                                      )
                                    },
                                    expression: "rubric.codRubrIderubrica",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { sm: "3" } },
                              [
                                _c("v-text-field", {
                                  class: {
                                    hasError:
                                      _vm.$v.rubric.ideTabRubrIderubrica.$error,
                                  },
                                  attrs: {
                                    maxlength: "8",
                                    id: "textIdTab",
                                    counter: 8,
                                    label: "Id. Tabela rubrica",
                                    dense: "",
                                    outlined: "",
                                    "persistent-placeholder": "",
                                    placeholder: _vm.obrigatorio,
                                  },
                                  on: { input: _vm.isValid },
                                  model: {
                                    value: _vm.rubric.ideTabRubrIderubrica,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.rubric,
                                        "ideTabRubrIderubrica",
                                        $$v
                                      )
                                    },
                                    expression: "rubric.ideTabRubrIderubrica",
                                  },
                                }),
                                _vm.validOrNot === false
                                  ? _c("div", [_vm._v("Caracter invalido")])
                                  : _vm._e(),
                                !_vm.$v.rubric.ideTabRubrIderubrica.maxLength
                                  ? _c(
                                      "div",
                                      { staticClass: "summary text-red" },
                                      [
                                        _vm._v(
                                          " O identificador de rubrica deve ter no máximo 8 caracteres "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                !_vm.$v.rubric.ideTabRubrIderubrica.minLength
                                  ? _c(
                                      "div",
                                      { staticClass: "summary text-red" },
                                      [
                                        _vm._v(
                                          " O identificador de rubrica deve ter no mínimo 2 caracteres "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { sm: "6" } },
                              [
                                _c("v-autocomplete", {
                                  class: {
                                    hasError:
                                      _vm.$v.rubric.nrInscIdeempregador.$error,
                                  },
                                  attrs: {
                                    label: "Empresa",
                                    "append-icon": "mdi-chevron-down",
                                    id: "idACEmpresa",
                                    items: _vm.userEstablishment,
                                    "item-text": "socialName",
                                    "item-value": (item) =>
                                      `${item.details.cnpj}`,
                                    multiple: false,
                                    dense: "",
                                    outlined: "",
                                    "persistent-placeholder": "",
                                    placeholder: _vm.obrigatorio,
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "item",
                                      fn: function ({ item, attrs, on }) {
                                        return [
                                          _c(
                                            "v-list-item",
                                            _vm._g(
                                              _vm._b(
                                                {
                                                  attrs: {
                                                    id: item.details.cnpj,
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "default",
                                                        fn: function ({
                                                          active,
                                                        }) {
                                                          return [
                                                            _c(
                                                              "v-list-item-content",
                                                              {
                                                                staticClass:
                                                                  "text-no-wrap",
                                                                staticStyle: {
                                                                  color:
                                                                    "primary",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      item.socialName
                                                                    )
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    true
                                                  ),
                                                },
                                                "v-list-item",
                                                attrs,
                                                false
                                              ),
                                              on
                                            )
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                  model: {
                                    value: _vm.rubric.nrInscIdeempregador,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.rubric,
                                        "nrInscIdeempregador",
                                        $$v
                                      )
                                    },
                                    expression: "rubric.nrInscIdeempregador",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { attrs: { sm: "6" } },
                              [
                                _c("v-autocomplete", {
                                  class: {
                                    hasError:
                                      _vm.$v.rubric.tpRubrDadosrubrica.$error,
                                  },
                                  attrs: {
                                    label: "Tipo",
                                    id: "idACTipe",
                                    items: _vm.listaTpRubr,
                                    "append-icon": "mdi-chevron-down",
                                    "item-text": "key",
                                    multiple: false,
                                    dense: "",
                                    "hide-details": "",
                                    outlined: "",
                                    "persistent-placeholder": "",
                                    placeholder: _vm.obrigatorio,
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "item",
                                      fn: function ({ item, attrs, on }) {
                                        return [
                                          _c(
                                            "v-list-item",
                                            _vm._g(
                                              _vm._b(
                                                {
                                                  attrs: {
                                                    id: item.key.substring(
                                                      0,
                                                      10
                                                    ),
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "default",
                                                        fn: function ({
                                                          active,
                                                        }) {
                                                          return [
                                                            _c(
                                                              "v-list-item-content",
                                                              {
                                                                staticClass:
                                                                  "text-wrap",
                                                                staticStyle: {
                                                                  color:
                                                                    "primary",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      item.key
                                                                    )
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    true
                                                  ),
                                                },
                                                "v-list-item",
                                                attrs,
                                                false
                                              ),
                                              on
                                            )
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                  model: {
                                    value: _vm.rubric.tpRubrDadosrubrica,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.rubric,
                                        "tpRubrDadosrubrica",
                                        $$v
                                      )
                                    },
                                    expression: "rubric.tpRubrDadosrubrica",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { sm: "6" } },
                              [
                                _c("v-autocomplete", {
                                  class: {
                                    hasError:
                                      _vm.$v.rubric.natRubrDadosrubrica.$error,
                                  },
                                  attrs: {
                                    id: "idACNature",
                                    label: "Natureza",
                                    items: _vm.listaNaturezas,
                                    "item-text": "key",
                                    "append-icon": "mdi-chevron-down",
                                    multiple: false,
                                    dense: "",
                                    "hide-details": "",
                                    outlined: "",
                                    "persistent-placeholder": "",
                                    placeholder: _vm.obrigatorio,
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "item",
                                      fn: function ({ item, attrs, on }) {
                                        return [
                                          _c(
                                            "v-list-item",
                                            _vm._g(
                                              _vm._b(
                                                {
                                                  attrs: {
                                                    id: item.key.substring(
                                                      0,
                                                      4
                                                    ),
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "default",
                                                        fn: function ({
                                                          active,
                                                        }) {
                                                          return [
                                                            _c(
                                                              "v-list-item-content",
                                                              {
                                                                staticClass:
                                                                  "text-wrap",
                                                                staticStyle: {
                                                                  color:
                                                                    "primary",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      item.key
                                                                    )
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    true
                                                  ),
                                                },
                                                "v-list-item",
                                                attrs,
                                                false
                                              ),
                                              on
                                            )
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                  model: {
                                    value: _vm.rubric.natRubrDadosrubrica,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.rubric,
                                        "natRubrDadosrubrica",
                                        $$v
                                      )
                                    },
                                    expression: "rubric.natRubrDadosrubrica",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { sm: "3" } },
                              [
                                _c("v-text-field", {
                                  directives: [
                                    {
                                      name: "mask",
                                      rawName: "v-mask",
                                      value: "##/####",
                                      expression: "'##/####'",
                                    },
                                  ],
                                  class: {
                                    hasError:
                                      _vm.$v.rubric.iniValidIderubrica.$error,
                                  },
                                  attrs: {
                                    id: "textIniValid",
                                    label: "Início da validade",
                                    placeholder: "mm/aaaa",
                                    rules: _vm.rulesInivalid,
                                    dense: "",
                                    outlined: "",
                                    "persistent-placeholder": "",
                                  },
                                  model: {
                                    value: _vm.rubric.iniValidIderubrica,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.rubric,
                                        "iniValidIderubrica",
                                        $$v
                                      )
                                    },
                                    expression: "rubric.iniValidIderubrica",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { sm: "3" } },
                              [
                                _c("v-text-field", {
                                  directives: [
                                    {
                                      name: "mask",
                                      rawName: "v-mask",
                                      value: "##/####",
                                      expression: "'##/####'",
                                    },
                                  ],
                                  class: {
                                    hasError:
                                      _vm.$v.rubric.fimValidIderubrica.$error,
                                  },
                                  attrs: {
                                    label: "Fim da validade",
                                    id: "textfimValid",
                                    placeholder: "mm/aaaa",
                                    rules: _vm.validFim,
                                    dense: "",
                                    outlined: "",
                                    "persistent-placeholder": "",
                                  },
                                  model: {
                                    value: _vm.rubric.fimValidIderubrica,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.rubric,
                                        "fimValidIderubrica",
                                        $$v
                                      )
                                    },
                                    expression: "rubric.fimValidIderubrica",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { sm: "6" } },
                              [
                                _c("v-autocomplete", {
                                  class: {
                                    hasError:
                                      _vm.$v.rubric.codIncCPDadosrubrica.$error,
                                  },
                                  attrs: {
                                    label: "Cod. Inc. CP",
                                    items: _vm.listaCodIncCP,
                                    id: "idACCodCP",
                                    "item-text": "key",
                                    multiple: false,
                                    dense: "",
                                    "hide-details": "",
                                    "append-icon": "mdi-chevron-down",
                                    outlined: "",
                                    "persistent-placeholder": "",
                                    placeholder: _vm.obrigatorio,
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "item",
                                      fn: function ({ item, attrs, on }) {
                                        return [
                                          _c(
                                            "v-list-item",
                                            _vm._g(
                                              _vm._b(
                                                {
                                                  attrs: {
                                                    id: item.key.substring(
                                                      0,
                                                      7
                                                    ),
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "default",
                                                        fn: function ({
                                                          active,
                                                        }) {
                                                          return [
                                                            _c(
                                                              "v-list-item-content",
                                                              {
                                                                staticClass:
                                                                  "text-wrap",
                                                                staticStyle: {
                                                                  color:
                                                                    "#000000",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      item.key
                                                                    )
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    true
                                                  ),
                                                },
                                                "v-list-item",
                                                attrs,
                                                false
                                              ),
                                              on
                                            )
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                  model: {
                                    value: _vm.rubric.codIncCPDadosrubrica,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.rubric,
                                        "codIncCPDadosrubrica",
                                        $$v
                                      )
                                    },
                                    expression: "rubric.codIncCPDadosrubrica",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { sm: "6" } },
                              [
                                _c("v-autocomplete", {
                                  class: {
                                    hasError:
                                      _vm.$v.rubric.codIncIRRFDadosrubrica
                                        .$error,
                                  },
                                  attrs: {
                                    label: "Cod. Inc. IRRF",
                                    items: _vm.listaCodIncIR,
                                    id: "idACCodIRRF",
                                    "item-text": "key",
                                    multiple: false,
                                    "append-icon": "mdi-chevron-down",
                                    dense: "",
                                    "hide-details": "",
                                    outlined: "",
                                    "persistent-placeholder": "",
                                    placeholder: _vm.obrigatorio,
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "item",
                                      fn: function ({ item, attrs, on }) {
                                        return [
                                          _c(
                                            "v-list-item",
                                            _vm._g(
                                              _vm._b(
                                                {
                                                  attrs: {
                                                    id: item.key.substring(
                                                      0,
                                                      7
                                                    ),
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "default",
                                                        fn: function ({
                                                          active,
                                                        }) {
                                                          return [
                                                            _c(
                                                              "v-list-item-content",
                                                              {
                                                                staticClass:
                                                                  "text-wrap",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      item.key
                                                                    )
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    true
                                                  ),
                                                },
                                                "v-list-item",
                                                attrs,
                                                false
                                              ),
                                              on
                                            )
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                  model: {
                                    value: _vm.rubric.codIncIRRFDadosrubrica,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.rubric,
                                        "codIncIRRFDadosrubrica",
                                        $$v
                                      )
                                    },
                                    expression: "rubric.codIncIRRFDadosrubrica",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { sm: "6" } },
                              [
                                _c("v-autocomplete", {
                                  class: {
                                    hasError:
                                      _vm.$v.rubric.codIncFGTSDadosrubrica
                                        .$error,
                                  },
                                  attrs: {
                                    label: "Cod. Inc. FGTS",
                                    items: _vm.listaCodIncFgts,
                                    id: "idACCodFGTS",
                                    "item-text": "key",
                                    multiple: false,
                                    dense: "",
                                    "append-icon": "mdi-chevron-down",
                                    "hide-details": "",
                                    outlined: "",
                                    "persistent-placeholder": "",
                                    placeholder: _vm.obrigatorio,
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "item",
                                      fn: function ({ item, attrs, on }) {
                                        return [
                                          _c(
                                            "v-list-item",
                                            _vm._g(
                                              _vm._b(
                                                {
                                                  attrs: {
                                                    id: item.key.substring(
                                                      0,
                                                      7
                                                    ),
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "default",
                                                        fn: function ({
                                                          active,
                                                        }) {
                                                          return [
                                                            _c(
                                                              "v-list-item-content",
                                                              {
                                                                staticClass:
                                                                  "text-wrap",
                                                                staticStyle: {
                                                                  color:
                                                                    "primary",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      item.key
                                                                    )
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    true
                                                  ),
                                                },
                                                "v-list-item",
                                                attrs,
                                                false
                                              ),
                                              on
                                            )
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                  model: {
                                    value: _vm.rubric.codIncFGTSDadosrubrica,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.rubric,
                                        "codIncFGTSDadosrubrica",
                                        $$v
                                      )
                                    },
                                    expression: "rubric.codIncFGTSDadosrubrica",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { sm: "12" } },
                              [
                                _c("v-textarea", {
                                  class: {
                                    hasError:
                                      _vm.$v.rubric.observacaoDadosrubrica
                                        .$error,
                                  },
                                  attrs: {
                                    maxlength: "255",
                                    label: "Observação",
                                    id: "textObserv",
                                    counter: 255,
                                    minlength: "1",
                                    dense: "",
                                    outlined: "",
                                    "persistent-placeholder": "",
                                  },
                                  model: {
                                    value: _vm.rubric.observacaoDadosrubrica,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.rubric,
                                        "observacaoDadosrubrica",
                                        $$v
                                      )
                                    },
                                    expression: "rubric.observacaoDadosrubrica",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm.invalidForm
                          ? _c("div", { staticClass: "summary text-red" }, [
                              _vm._v("Preencher todos os campos obrigatórios"),
                            ])
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                  _c("v-tab", [_vm._v("Processos")]),
                  _c("v-tab-item", [
                    _c(
                      "div",
                      { staticClass: "pa-5" },
                      [
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "12" } },
                              [
                                _c("v-autocomplete", {
                                  attrs: {
                                    label:
                                      "Processos CP(Código do indicativo da suspensão)",
                                    "append-icon": "mdi-chevron-down",
                                    items: _vm.listaProcessoCp,
                                    "item-text": (item) =>
                                      `${item.nrProcIdeProcessoCP}(${item.codSuspIdeProcessoCP}) `,
                                    multiple: true,
                                    dense: "",
                                    "return-object": "",
                                    "hide-details": "",
                                    outlined: "",
                                    "persistent-placeholder": "",
                                  },
                                  model: {
                                    value:
                                      _vm.rubric.listEvtTabRubricaIdeProcessoCP,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.rubric,
                                        "listEvtTabRubricaIdeProcessoCP",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "rubric.listEvtTabRubricaIdeProcessoCP",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c("br"),
                            _c("br"),
                            _c(
                              "v-col",
                              { attrs: { cols: "12" } },
                              [
                                _c("v-autocomplete", {
                                  attrs: {
                                    label:
                                      "Processos FGTS(Código do indicativo da suspensão)",
                                    items: _vm.listaProcessoFgts,
                                    "item-text": (item) =>
                                      `${item.nrProcIdeProcessoFGTS}`,
                                    multiple: true,
                                    dense: "",
                                    "append-icon": "mdi-chevron-down",
                                    "hide-details": "",
                                    "return-object": "",
                                    outlined: "",
                                    "persistent-placeholder": "",
                                  },
                                  model: {
                                    value:
                                      _vm.rubric
                                        .listEvtTabRubricaIdeProcessoFGTS,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.rubric,
                                        "listEvtTabRubricaIdeProcessoFGTS",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "rubric.listEvtTabRubricaIdeProcessoFGTS",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c("br"),
                            _c("br"),
                            _c(
                              "v-col",
                              { attrs: { cols: "12" } },
                              [
                                _c("v-autocomplete", {
                                  attrs: {
                                    label:
                                      "Processos IRRF(Código do indicativo da suspensão)",
                                    items: _vm.listaProcessoIrrf,
                                    "item-text": (item) =>
                                      `${item.nrProcIdeProcessoIRRF}(${item.codSuspIdeProcessoIRRF})`,
                                    multiple: true,
                                    dense: "",
                                    "return-object": "",
                                    "append-icon": "mdi-chevron-down",
                                    "hide-details": "",
                                    outlined: "",
                                    "persistent-placeholder": "",
                                  },
                                  model: {
                                    value:
                                      _vm.rubric
                                        .listEvtTabRubricaIdeProcessoIRRF,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.rubric,
                                        "listEvtTabRubricaIdeProcessoIRRF",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "rubric.listEvtTabRubricaIdeProcessoIRRF",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c("v-tab", [_vm._v("Aplicabilidade")]),
                  _c("v-tab-item", [
                    _c(
                      "div",
                      { staticClass: "pa-5" },
                      [
                        _c("v-textarea", {
                          attrs: {
                            counter: "8000",
                            maxlength: "8000",
                            label: "Aplicabilidade",
                            dense: "",
                            outlined: "",
                            "persistent-placeholder": "",
                          },
                          model: {
                            value: _vm.rubric.aplicabilidade,
                            callback: function ($$v) {
                              _vm.$set(_vm.rubric, "aplicabilidade", $$v)
                            },
                            expression: "rubric.aplicabilidade",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    { attrs: { text: "" }, on: { click: _vm.close } },
                    [_vm._v(" Cancelar")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "success",
                        loading: _vm.loadingSave,
                        disabled: _vm.enableSaveButton,
                      },
                      on: { click: _vm.salvar },
                    },
                    [_vm._v(" Salvar ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }